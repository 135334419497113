<template>
  <div>
    <div id="wrap">
      <div class="hero d-flex align-items-center" title="hero">
        <div class="container d-flex hero-container">
          <p class="hero-word">今までの実績を可視化したい<br/>
          Fanと交流したい配信者の為の</p>

          <h1><img src="/img/logo.png" class="hero-logo mb-4" alt="YoutubeHub | 【今までの実績を可視化する】" /></h1>

          <p class="hero-description">今まで頑張って来たけどどれくらい頑張ったかよくわからない・・・<br/>
          Fanとの交流疎かにしてませんか？YoutubeHubがあなたの実績・Fanを教えてくれます！</p>
          <div id="firebaseui-auth-container"></div>
          <div id="loading" class="spinner-border" role="status"></div>
        </div>
      </div>
      <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 100 100" preserveAspectRatio="none" id="svg-bg">
        <path d="M0,0 v90 q10,10 20,0 t20,0 t20,0 t20,0 t20,0 v-90 Z" fill="#ffa3a9" />
      </svg>
    </div>

    <section class="container">
      <img src="/img/logo.png" class="hiro-logo" alt="logo" />
      <p>って何？</p>
      <p>①投稿数によってカレンダーに草を生やしたグラフが取得出来ます</p>
      <img src="/img/contribution.png" class="contribution" />
      <p>②チャンネル登録してくれている人のチャンネルがわかります</p>
    </section>

    <section class="container questions my-4">
      <h2>よくある質問</h2>
      <p>
        <span class="text-danger">Q</span>: 何が出来るの？
      </p>
      <p>
        <span class="text-info">A</span>: 過去に投稿した動画をもとに草を生やせます。自分のチャンネルの登録者がわかります。毎日頑張ってるなぁと自己肯定や頑張ってるアピールに使って下さい。
      </p>

      <p>
        <span class="text-danger">Q</span>: ○○さんが登録してくれてない！！
      </p>
      <p>
        <span class="text-info">A</span>: 制約があり登録チャンネルを公開している方のみが対象となってます。いなくて気になった方がいた場合、その方に直接聞いてみて下さい。
      </p>

      <p>
        <span class="text-danger">Q</span>: 急にサイトが使えなくなった！
      </p>
      <p>
        <span class="text-info">A</span>: いろんなパターンがあると思いますが、一番あり得るのがGoogleさんの事情で1日に使用上限があり、それに引っかかっている場合使用出来なくなります。バグの可能性もあるので1日立って復活しなかった場合連絡下さい。
      </p>

      <p>
        <span class="text-danger">Q</span>: うちのブラウザ挙動おかしいんだけど・・・
      </p>
      <p>
        <span class="text-info">A</span>: コスパの関係によりChromeでしか動作確認してない為、他ブラウザで動かない可能性があります。連絡があれば対応出来るかもしれません。
      </p>

      <p>
        <span class="text-danger">Q</span>: 誰が作ったの？何目的？
      </p>
      <p>
        <span class="text-info">A</span>: 暇を持て余したエンジニアが何か作ろうと思ったけどネタが浮かばないので、
        <a href="https://twitter.com/hitotosetamaki" target="_blank" rel="noopener noreferrer">知り合い</a>がネタ提供してくれたのを雑に作成した(半分ジョーク)サイト。
      </p>

      <p>
        <span class="text-danger">Q</span>: 無料なの？
      </p>
      <p>
        <span class="text-info">A</span>: 完全無料です。ただし維持費は掛かっている為、管理出来なくなった場合終了になります。<a href="https://twitter.com/hitotosetamaki" target="_blank" rel="noopener noreferrer">運営</a>の<a href="https://www.youtube.com/channel/UCe03vkOgtiMVWm-knu2TUkQ?sub_confirmation=1" target="_blank" rel="noopener noreferrer">チャンネル登録</a>したり干し芋送ったり(etc)ご支援があると長続きするかもしれません。（維持費折半なんで
      </p>

      <p>
        <span class="text-danger">Q</span>: こういう機能欲しいな〜
      </p>
      <p>
        <span class="text-info">A</span>: 要望を上げて下さい。暇があれば対応します。
      </p>

      <p>
        <span class="text-danger">Q</span>: これバグってるんだけど
      </p>
      <p>
        <span class="text-info">A</span>: すみません気付いてない事が多々あるのでフォームから教えて下さい。。
      </p>

      <p>
        <span class="text-danger">Q</span>: どこに問い合わせればいい？
      </p>
      <p>
        <span class="text-info">A</span>:
        <a href="https://docs.google.com/forms/d/e/1FAIpQLSfrAb-6f-c36MR0F27D8tsxR1Ul-9zBl8SFgyxJHsTSdtkArg/viewform" target="_blank" rel="noopener noreferrer">こちらのフォーム</a>
        からご連絡お願いします。
      </p>
    </section>
    <Share />
    <footer>
      Copyright © 2021 <a href="https://twitter.com/hitotosetamaki" target="_blank" rel="noopener noreferrer">運営</a>
    </footer>
  </div>
</template>

<script>
import * as firebaseui from 'firebaseui-ja';
import firebase from 'firebase';
import Cookies from 'universal-cookie';
import moment from 'moment';
import Share from '../components/Share';

export default {
  name: 'Home',
  components: { Share },
  methods: {},
  head: {
    title () {
      return {
        inner: 'YoutubeHub',
        separator: '|',
        complement: '【今までの実績を可視化する】'
      };
    },
    meta () {
      return [{ name: 'title', content: 'YoutubeHub【今までの実績を可視化する】' },
        { name: 'viewport', content: 'width=device-width,initial-scale=1.0,user-scalable=no' },
        { charset: 'utf-8' },
        { property: 'og:type', content: 'website' },
        { name: 'description', content: 'Youtube配信者に捧げる。過去の実績を草グラフに可視化します！自分をフォローしてくれているFAN一覧を見て自己肯定感を高めよう！' },
        { property: 'og:title', content: 'YoutubeHub【今までの実績を可視化する】' },
        { property: 'og:description', content: 'Youtube配信者に捧げる。過去の実績を草グラフに可視化します！自分をフォローしてくれているFAN一覧を見て自己肯定感を高めよう！' },
        { property: 'og:type', content: 'website' },
        { property: 'og:url', content: 'https://youtube-hub.com/' },
        { property: 'og:image', content: '/img/button_channel_touroku.png' },
        { name: 'twitter:card', content: 'app' }];
    }
  },
  mounted () {
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        this.$analytics.setUserId(user.uid);
        this.$analytics.setUserProperties({
          account_type: 'Basic' // can help you to define audiences
        });
      }
    });
    this.$analytics.logEvent('notification_received');
    this.$nextTick(function () {
      // Initialize the FirebaseUI Widget using Firebase.
      var ui = new firebaseui.auth.AuthUI(firebase.auth());
      var uiConfig = {
        callbacks: {
          signInSuccessWithAuthResult: function (authResult) {
            var token = authResult.credential.accessToken;
            const cookies = new Cookies();
            cookies.set('token', token, { expires: moment().add(1, 'hour').toDate() });
            return true;
          },
          uiShown: async function () {
            const button = await document.getElementsByClassName('firebaseui-idp-button')[0];
            button.style.background = '#f1f1f1';
            document.getElementsById('loading').style.display = 'block';
          }
        },
        // Will use popup for IDP Providers sign-in flow instead of the default, redirect.
        signInFlow: 'popup',
        // redirect page
        signInSuccessUrl: '/subscription',
        signInOptions: [
          {
            provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
            scopes: [
              'https://www.googleapis.com/auth/youtube.readonly'
            ]
          }
        ]
        // Terms of service url.
        // tosUrl: '<your-tos-url>',
        // Privacy policy url.
        // privacyPolicyUrl: '<your-privacy-policy-url>'
      };
      ui.start('#firebaseui-auth-container', uiConfig);
    });
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#wrap {
    height: 100%;
    position: relative;
}
#svg-bg {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: -1;
}
#loading {
  display: none;
}
.hero {
  height: 500px;
}

.hero-logo {
  width: 200px;
}

.hero-container {
  flex-direction: column;
  align-items: center;
}

.hero-description {
  background: RGB(255, 255, 255, 0.4);
  border-radius: 15px;
  padding: 5px 10px;
  max-width: 700px;
}

.hero-word {
  font-size: 20px;
}

.hero-title {
  font-size: 40px;
}

.contribution {
  max-width: 720px;
  width: 100%;
}

.question-image-container {
  flex-direction: column;
  align-items: center;
}

.questions {
  max-width: 720px;
}
</style>
