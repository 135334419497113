import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import 'firebaseui/dist/firebaseui.css';
import { firebaseInit } from './plugins/firebase';
import VueHead from 'vue-head';

// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import '@fortawesome/fontawesome-free/js/fontawesome';
import '@fortawesome/fontawesome-free/js/brands';
import '@fortawesome/fontawesome-free/js/solid';
import '@fortawesome/fontawesome-free/js/regular';

require('jquery/dist/jquery');
require('popper.js/dist/umd/popper');
require('bootstrap/dist/js/bootstrap');

const fb = firebaseInit();
// work around https://qiita.com/laineus/items/2b00aca6aa64a455e556
export default function install (app, fb) {
  app.config.globalProperties.$head = VueHead;
  app.config.globalProperties.$analytics = fb.analytics();
};

const app = createApp(App);
install(app, fb);
app.use(store);
app.use(router);
app.use({
  head: {
    title () {
      return {
        inner: 'YoutubeHub',
        separator: '|',
        complement: '【今までの実績を可視化する】'
      };
    },
    meta () {
      return [{ name: 'title', content: 'YoutubeHub【今までの実績を可視化する】' },
        { name: 'viewport', content: 'width=device-width,initial-scale=1.0,user-scalable=no' },
        { charset: 'utf-8' },
        { property: 'og:type', content: 'website' },
        { name: 'description', content: 'Youtube配信者に捧げる。過去の実績を草グラフに可視化します！自分をフォローしてくれているFAN一覧を見て自己肯定感を高めよう！' },
        { property: 'og:title', content: 'YoutubeHub【今までの実績を可視化する】' },
        { property: 'og:description', content: 'Youtube配信者に捧げる。過去の実績を草グラフに可視化します！自分をフォローしてくれているFAN一覧を見て自己肯定感を高めよう！' },
        { property: 'og:type', content: 'website' },
        { property: 'og:url', content: 'https://youtube-hub.com/' },
        { property: 'og:image', content: '/img/button_channel_touroku.png' },
        { name: 'twitter:card', content: 'summary' }];
    }
  }
});
app.mount('#app');
