import { createStore } from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import moment from 'moment';

export default createStore({
  state: {
    apiResults: {},
    channel: null
  },
  mutations: {
    updateApiResults (state, { date, results }) {
      const year = moment(date).get('year');
      state.apiResults[year] = results;
    },
    setChannel (state, channel) {
      state.channel = channel;
    }
  },
  actions: {
  },
  modules: {
  },
  plugins: [
    createPersistedState({
      key: 'youtubeHub',
      storage: window.sessionStorage
    })
  ]
});
