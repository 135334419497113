// Firebase App (the core Firebase SDK) is always required and must be listed first
import firebase from 'firebase/app';
import 'firebase/analytics';

const firebaseConfig = {
  apiKey: 'AIzaSyAurzOf3d9YKunS5CtW3MxFZa0t3Gf4zgk',
  authDomain: 'hub-bfc94.firebaseapp.com',
  projectId: 'hub-bfc94',
  storageBucket: 'hub-bfc94.appspot.com',
  messagingSenderId: '655862744919',
  appId: '1:655862744919:web:94d0c9e6d2d0242205a012',
  measurementId: 'G-VGMNNPNS93'
};

export function firebaseInit () {
  firebase.initializeApp(firebaseConfig);
  firebase.analytics();
  return firebase;
}
