<template>
  <div class="p-sns d-flex align-items-center my-2">
    <ul class="p-sns__list row p-0 mx-auto">
      <li class="p-sns__item -facebook col-auto">
        <a href="http://www.facebook.com/share.php?u=https://youtube-hub.com" rel="nofollow noopener" target="_blank"><i class="fab fa-facebook-square"></i></a>
      </li>
      <li class="p-sns__item -twitter col-auto">
        <a href="https://twitter.com/intent/tweet?url=https://youtube-hub.com&hashtags=youtubehub" rel="nofollow noopener" target="_blank"><i class="fab fa-twitter"></i></a>
      </li>
      <!-- <li class="p-sns__item -hatebu col-auto">
        <a href="http://b.hatena.ne.jp/add?mode=confirm&url=https://youtube-hub.com&title=YoutubeHub" target="_blank" rel="nofollow noopener">はてブ</a>
      </li> -->
      <li class="p-sns__item -pocket col-auto">
        <a href="http://getpocket.com/edit?url=https://youtube-hub.com&title=YoutubeHub" rel="nofollow noopener" target="_blank"><i class="fab fa-get-pocket"></i></a>
      </li>
      <li class="p-sns__item -line col-auto">
        <a href="https://timeline.line.me/social-plugin/share?url=https://youtube-hub.com" rel="nofollow noopener" target="_blank"><i class="fab fa-line"></i></a>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'Share',
  components: {}
};
</script>

<style>
.p-sns__list {
  list-style: none;
}
</style>
