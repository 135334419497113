import axios from 'axios';

// 1quo:
export async function getSubscriptions (params) {
  let data = null;
  await axios
    .get('https://www.googleapis.com/youtube/v3/subscriptions', {
      params: params
    })
    .then((res) => {
      data = res.data;
    });
  return data;
}

// 100quo:
export async function getSearch (params) {
  let data = null;
  await axios
    .get('https://www.googleapis.com/youtube/v3/search', {
      params: params
    })
    .then((res) => {
      data = res.data;
    });
  return data;
}

// 1quo:
export async function getChannels (params) {
  let data = null;
  await axios
    .get('https://www.googleapis.com/youtube/v3/channels', {
      params: params
    })
    .then((res) => {
      data = res.data;
    });
  return data;
}
