<template>
  <header>
    <nav class="navbar navbar-light bg-light justify-content-lg-around">
      <a class="navbar-brand" href="/"><img src="/img/logo.png" class="w-100" /></a>
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarNav">
        <div class="d-flex justify-content-lg-around">
          <ul class="navbar-nav">
            <li class="nav-item">
              <router-link to="/contribution" :class="classes.contribution ? 'disabled text-secondary' : ''">Contribution</router-link>
            </li>
            <li class="nav-item">
              <router-link to="/subscription" :class="classes.subscription ? 'disabled text-secondary' : ''">Subscription</router-link>
            </li>
          </ul>
          <ul></ul>
        </div>
      </div>
    </nav>
  </header>
</template>

<script>
export default {
  name: 'AppHeader',
  components: {},
  props: {
    classes: {
      type: Object,
      required: true
    }
  }
};
</script>

<style>
header {
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  z-index: 10;
}

.navbar-brand {
  width: 144px;
}

.disabled {
  pointer-events: none;
  text-decoration: line-through;
}
</style>
