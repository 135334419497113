<template>
  <div class="contribution">
    <AppHeader :classes="{contribution: true}" />
    <section class="container my-4 my-profile" v-if="$store.state.channel">
      <div class="card">
        <a :href="'https://www.youtube.com/channel/' + $store.state.channel.id" target="_blank" rel="noopener noreferrer">
          <img class="card-img-top" :src="$store.state.channel.snippet.thumbnails.medium.url" alt="channel image">
        </a>
        <div class="card-body">
          <h5 class="card-title">{{ $store.state.channel.snippet.title }}</h5>
        </div>
        <ul class="list-group list-group-flush">
          <li class="list-group-item">登録者数: {{ $store.state.channel.statistics.subscriberCount }}</li>
          <li class="list-group-item">再生数: {{ $store.state.channel.statistics.viewCount }}</li>
          <li class="list-group-item">投稿数: {{ $store.state.channel.statistics.videoCount }}</li>
        </ul>
      </div>
    </section>
    <div class="cal-heatmap-wrap mb-2">
      <div id="cal-heatmap"></div>
      <div class="btn-group" role="group" aria-label="contribution-label">
        <button @click="setContributions('left')" type="button" class="btn btn-primary"><i class="fas fa-arrow-left"></i></button>
        <button type="button" class="btn btn-primary" id="download">Download</button>
        <button type="button" class="btn btn-primary"><a href="https://twitter.com/intent/tweet?text=ダウンロードしたファイルを貼って下さい&url=https://youtube-hub.com&hashtags=youtubehub" target="_blank" rel="noopener" class="text-white">Tweet</a></button>
        <button @click="setContributions('right')" type="button" class="btn btn-primary"><i class="fas fa-arrow-right"></i></button>
      </div>
    </div>
    <Share />
    <div id="onClick-placeholder" class="video-container container mb-4">
      <p v-if="selectedDateTitle && selectedVideos.length > 0">{{ selectedDateTitle() }}</p>
      <template v-for="video in selectedVideos" :key="video.id.videoId">
        <div class="movie-wrap mt-4" style="position: relative;">
          <iframe
            :src="'https://www.youtube.com/embed/' + video.id.videoId"
            frameborder="0" allowfullscreen>
          </iframe>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import CalHeatMap from 'cal-heatmap';
import Cookies from 'universal-cookie';
import moment from 'moment';
import { getSearch, getChannels } from '../plugins/youtubeApi';
import * as d3 from 'd3';
import AppHeader from '../components/AppHeader';
import firebase from 'firebase';
import Share from '../components/Share';

export default {
  name: 'Contribution',
  components: { AppHeader, Share },
  data: function () {
    return {
      now: moment(),
      searchResults: null,
      videos: null,
      selectedVideos: [],
      searchParams: {
        part: 'snippet',
        channelId: null,
        publishedAfter: null,
        publishedBefore: null,
        pageToken: null,
        order: 'date',
        maxResults: 50,
        access_token: new Cookies().get('token')
      },
      channelParams: {
        part: 'id,snippet,statistics',
        mine: true,
        access_token: new Cookies().get('token')
      }
    };
  },
  methods: {
    setContributions (mode) {
      if (new Cookies().get('token') === undefined) {
        this.$router.push({ name: 'Home' });
      }
      if (mode === 'left') {
        this.now = this.now.add('year', -1);
      } else {
        this.now = this.now.add('year', 1);
      }
      this.createCalHeatMap();
    },
    selectedDateTitle () {
      const place = document.getElementById('onClick-placeholder');
      if (place === null) return '';
      return moment(place.dataset.selectedDate).format('YYYY-MM-DD 活動詳細');
    },
    getChannel: async function () {
      if (this.$store.state.channel === null) {
        const channel = await getChannels(this.channelParams);
        this.$store.commit('setChannel', await channel.items.find(a => a.id !== ''));
      }
      return this.$store.state.channel;
    },
    showVideos () {
      const place = document.getElementById('onClick-placeholder');
      const date = place.dataset.selectedDate;
      const startTime = moment(date).startOf('day').unix();
      const endTime = moment(date).endOf('day').unix();
      this.selectedVideos = this.videos.filter((video) => {
        const formatDate = moment(video.snippet.publishedAt).unix();
        return startTime <= formatDate && formatDate <= endTime;
      });
    },
    settingGrass: async function () {
      this.searchParams.publishedAfter = this.now.startOf('year').toISOString();
      this.searchParams.publishedBefore = this.now.endOf('year').toISOString();
      const channel = await this.getChannel();
      this.searchParams.channelId = channel.id;
      let result;
      let tmp = [];
      // api save
      if (this.$store.state.apiResults[this.now.year()] === undefined) {
        this.searchParams.pageToken = null;
        result = await getSearch(this.searchParams);
        this.searchResults = result;
        tmp = tmp.concat(result.items);
        while (this.searchResults.nextPageToken) {
          this.searchParams.pageToken = this.searchResults.nextPageToken;
          result = await getSearch(this.searchParams);
          this.searchResults = result;
          tmp = tmp.concat(result.items);
        }
        await this.$store.commit('updateApiResults', { date: this.now, results: tmp });
      } else {
        tmp = this.$store.state.apiResults[this.now.year()];
      }

      this.videos = tmp;
      const test = this.videos
        .map(video => moment(video.snippet.publishedAt).unix())
        .reduce(function (prev, current) {
          prev[current] = (prev[current] || 0) + 1;
          return prev;
        }, {});
      return test;
    },
    createCalHeatMap: async function () {
      const parent = document.getElementById('cal-heatmap');
      // destroy workaround
      if (parent !== undefined) {
        while (parent.lastChild) {
          parent.removeChild(parent.lastChild);
        }
      }
      const cal = new CalHeatMap();
      cal.init({
        itemSelector: '#cal-heatmap',
        highlight: ['now'],
        domainGutter: 0,
        domain: 'year',
        subDomain: 'day',
        domainLabelFormat: '%Y 年 活動概要',
        itemName: ['活動', '活動'],
        subDomainDateFormat: function (date) {
          return moment(date).format('YYYY-MM-DD');
        },
        // test settings ↓
        // data: 'https://cal-heatmap.com/datas-years.json',
        // start: new Date(2000, 0),
        data: await this.settingGrass(),
        start: this.now.startOf('year').toDate(),
        dataType: 'json',
        cellSize: 15,
        range: 1,
        legendColors: ['#ebedf0', '#216e39'],
        legend: [1, 2, 3, 4],
        legendHorizontalPosition: 'right',
        displayLegend: true,
        label: {
          position: 'top'
        },
        onClick: function (date, nb) {
          const place = document.getElementById('onClick-placeholder');
          place.dataset.selectedDate = date;
        }
      });
      const gTag = document.getElementsByTagName('g');
      for (const tag of gTag) {
        tag.onclick = this.showVideos;
      }
      d3.select('#download').on('click', function () {
        d3.event.preventDefault();

        var svgData = cal.getSVG();
        var canvas = document.createElement('canvas');
        canvas.width = 900;
        canvas.height = 175;

        var ctx = canvas.getContext('2d');
        var image = new Image();
        image.onload = function () {
          ctx.drawImage(image, 0, 0);
          var a = document.createElement('a');
          a.href = canvas.toDataURL('image/png');
          a.setAttribute('download', 'contribution.png');
          a.dispatchEvent(new MouseEvent('click'));
        };
        image.src = 'data:image/svg+xml;charset=utf-8;base64,' + btoa(unescape(encodeURIComponent(svgData)));
      });
    }
  },
  mounted () {
    firebase.analytics().logEvent('page_view');
    if (new Cookies().get('token') === undefined) {
      this.$router.push({ name: 'Home' });
    }
    this.createCalHeatMap();
  }
};
</script>

<style>
#cal-heatmap {
  overflow-y: scroll;
}

.graph-label {
  font-size: 16px;
  font-weight: 400;
}

.domain-background {
  fill: white;
}

.my-profile {
  max-width: 360px;
}

.video-container {
  max-width: 600px;
}

.movie-wrap {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
}

.movie-wrap iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
</style>
