<template>
  <router-view/>
</template>

<script>
export default {
  name: 'App'
};
</script>

<style>
@import url(https://fonts.googleapis.com/css?family=Sawarabi+Gothic);
@import url(https://fonts.googleapis.com/css?family=M+PLUS+Rounded+1c);
@import url(https://fonts.googleapis.com/earlyaccess/nicomoji.css);

body {
  background: #fedfe1;
}

#app {
  font-family: 'M PLUS Rounded 1c', 'Nico Moji', Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}

a:link, a:visited, a:hover, a:active {
  text-decoration: none;
}

</style>
