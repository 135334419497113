<template>
  <div>
    <AppHeader :classes="{subscription: true}" />
    <button @click="setSubscriptions('left')" v-if="subscriptions.prevPageToken !== undefined" type="button" class="btn btn-primary rounded-circle p-0 fixed-button-left" style="width:2rem;height:2rem;"><i class="fas fa-arrow-left"></i></button>
    <button @click="setSubscriptions('right')" v-if="subscriptions.nextPageToken !== undefined"  type="button" class="btn btn-primary rounded-circle p-0 fixed-button-right" style="width:2rem;height:2rem;"><i class="fas fa-arrow-right"></i></button>
    <div class="card-columns container my-4">
      <div class="card" v-for="(channel) in mySubscriptions" v-bind:key="channel.channelId">
        <a :href="'https://www.youtube.com/channel/' + channel.channelId + '?sub_confirmation=1'" target="_blank" rel="noopener noreferrer">
          <img class="card-img-top" :src="channel.imgUrl" alt="channel image">
        </a>
        <div class="card-body">
          <h5 class="card-title">{{ channel.title }}</h5>
          <p class="card-text">{{ channel.description }}</p>
        </div>
        <ul class="list-group list-group-flush">
          <li class="list-group-item">登録者数: {{ channel.subscriberCount }}</li>
          <li class="list-group-item">再生数: {{ channel.viewCount }}</li>
          <li class="list-group-item">投稿数: {{ channel.videoCount }}</li>
        </ul>
      </div>
    </div>
  </div>
  <Share />
</template>

<script>
import Cookies from 'universal-cookie';
import { getSubscriptions, getChannels } from '../plugins/youtubeApi';
import AppHeader from '../components/AppHeader';
import firebase from 'firebase';
import Share from '../components/Share';

export default {
  name: 'Subscription',
  components: { AppHeader, Share },
  data: function () {
    return {
      mySubscriptions: null,
      videos: [],
      subscriptions: {},
      subscribeParams: {
        part: 'snippet',
        mySubscribers: true,
        maxResults: 50,
        order: 'alphabetical',
        pageToken: null,
        access_token: new Cookies().get('token')
      },
      channelsParams: {
        part: 'snippet,statistics',
        id: '',
        maxResults: 50,
        access_token: new Cookies().get('token')
      }
    };
  },
  methods: {
    setSubscriptions: function (mode) {
      if (new Cookies().get('token') === undefined) {
        this.$router.push({ name: 'Home' });
      }
      if (mode === 'left') {
        this.subscribeParams.pageToken = this.subscriptions.prevPageToken;
      } else {
        this.subscribeParams.pageToken = this.subscriptions.nextPageToken;
      }
      this.settingSubscription();
    },
    settingSubscription: async function () {
      this.subscriptions = await getSubscriptions(this.subscribeParams);
      this.channelsParams.id = this.subscriptions.items
        .map(item => item.snippet.channelId)
        .join(',');

      const channels = await getChannels(this.channelsParams);
      this.mySubscriptions = channels.items
        .map((channel) => {
          return {
            channelId: channel.id,
            title: channel.snippet.title,
            description: channel.snippet.description,
            imgUrl: channel.snippet.thumbnails ? channel.snippet.thumbnails.medium.url : '../assets/button_subscribe.png',
            viewCount: channel.statistics.viewCount,
            videoCount: channel.statistics.videoCount,
            subscriberCount: channel.statistics.subscriberCount
          };
        });
    }
  },
  mounted () {
    firebase.analytics().logEvent('page_view');
    if (new Cookies().get('token') === undefined) {
      this.$router.push({ name: 'Home' });
    }
    this.channelsParams.nextPageToken = null;
    this.channelsParams.prevPageToken = null;
    this.settingSubscription();
  }
};
</script>

<style>
.fixed-button-left {
  position: fixed;
  bottom: 1rem;
  left: 1rem;
  padding: 6px 40px;
  z-index: 5;
}

.fixed-button-right {
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  padding: 6px 40px;
  z-index: 5;
}

.card {
  animation: SlideIn 2s;
  max-width: 360px;
  margin: 0 auto;
}

@keyframes SlideIn {
  0% {
    opacity: 0;
    transform: translateX(128px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
</style>
