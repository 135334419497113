import { createRouter, createWebHistory } from 'vue-router';
import Home from '../views/Home.vue';
import Subscription from '../views/Subscription.vue';
import Contribution from '../views/Contribution.vue';
import firebase from 'firebase';
import Cookies from 'universal-cookie';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      isPublic: true
    }
  },
  {
    path: '/contribution',
    name: 'Contribution',
    component: Contribution,
    meta: {
      isPublic: true
    }
  },
  {
    path: '/subscription',
    name: 'Subscription',
    component: Subscription,
    meta: {
      isPublic: true
    }
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach((to, from, next) => {
  // auth
  firebase.auth().onAuthStateChanged(function (user) {
    const cookie = new Cookies();
    if (to.name !== 'Home' && cookie.get('token') === undefined && user) next({ name: 'Home' });
    else next();
  });
});

export default router;
